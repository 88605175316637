import config from '../../appConfig';
import { Resource, SharedResources } from '../../reducks/share/reducer';
import { fetchJson, fetchJsonWithAuth } from '../../utils/fetch';
import { IIvsResponse } from '../IVSService/IIvsResponse';
import { IAnalyticsResponse } from './IAnalyticsResponse';
import type { IContentChatResponse } from './IContentChatResponse';
import { IMeResponse } from './IMeResponse';
import { IShareResponse } from './IShareResponse';
import { ISlidesResponse } from './ISlidesResponse';
import { ISttResponse } from './ISttResponse';
import { ITranscriptResponse } from './ITranscriptResponse';

export interface IToken {
  access_token: string;
}

export interface IUser {
  accountKey: string;
  userKey: string;
}

export interface ISessionUpdates {
  title: string;
  accountKey?: string;
}

export interface ISharePutObject {
  expires?: number;
  sharedResources?: SharedResources;
  scope?: {
    type: 'PUBLIC' | 'PRIVATE' | 'EMAIL',
    include?: string[],
    exclude?: string[],
  };
}

const baseURL = config.insightsService.url;
const alsBaseURL = config.als.url;

export const getMe = (token: IToken) => fetchJsonWithAuth<IMeResponse>(`${baseURL}/v1/products/g2m/me`, token);

export const alsCall = async (token, assetKey, sessionKey, languageCode) => {
  const url = `${alsBaseURL}/assets/${assetKey}?updateAssetAttributesOnly=false&includes=assetVersionDetails`;

  const body = JSON.stringify({

      resources: [
          {
              external: false,
              provider: 's3',
              resourceType: 'google_transcript',
              attributes: [
                  {
                      id: null,
                      name: 'transcriptionProvider',
                      value: 'voiceai',
                  },
                  {
                      id: null,
                      name: 'languageCode',
                      value: `${languageCode}`,
                  },
              ],
          },
          {
              external: false,
              provider: 's3',
              resourceType: 'parsed_transcript_v2',
              passthroughMetaData: `sessionKey:${sessionKey}`,
          },
      ],
  });

  try {
      const response = await fetchJsonWithAuth(url, token, {
          method: 'PUT',
          headers: {
              'cache-control': 'no-cache',
          },
          body,
      });
      return response;
  } catch (error) {
      return error;
  }
};

export const getShareData = (shareKey: string, token?: IToken, ivsToken?: IIvsResponse) => {
  const url = `${baseURL}/v2/products/g2m/${shareKey}/analytics`;
  const headers = ivsToken ? { 'X-IVS-Token': ivsToken.token } : undefined;

  if (token && token.access_token) {
    return fetchJsonWithAuth<IAnalyticsResponse>(url, token, { headers });
  } else {
    return fetchJson<IAnalyticsResponse>(url, { headers });
  }
};

export const getSessionData = (sessionKey: string, { accountKey, userKey }: IUser, token: IToken) =>
  fetchJsonWithAuth<IAnalyticsResponse>(
    `${baseURL}/v2/products/g2m/accounts/${accountKey}/users/${userKey}/sessions/${sessionKey}/analytics`,
    token
  );

export const getTranscript = (url: string) => fetchJson<ITranscriptResponse>(url);

export const getSttTranscript = (url?: string) => fetchJson<ISttResponse>(url);

export const getContentChat = async (url: string, locale: string) => {
 try {
  const data: IContentChatResponse = await fetchJson(url);
  return { data, locale };
 } catch (err) {
  throw err;
 }
};

export const getInsights = (url: string) => fetchJson(url);

export const getSlidesMetaData = (url: string) => fetchJson<ISlidesResponse>(url);

export const getShareFromSession = (sessionKey: string, token: IToken) =>
  fetchJsonWithAuth<IShareResponse>(`${baseURL}/v1/sharing/session/${sessionKey}`, token);

export const createShare = async (
  sessionKey: string,
  { accountKey, userKey }: IUser,
  token: IToken,
  expirationEpoch: number, // epoch
  newSharedResources: SharedResources,
  prevSharedResources: SharedResources,
  resource?: Resource,
  scopeType?: IShareResponse['scope'][0]['type'],
  scopeValue?: string,
  isDownloadable?: boolean,
  linkShareAuthenticationCodeEnabled?: boolean
) => {
  try {
    const isPrivate = scopeType === 'PRIVATE';
    const newPublicScope = isPrivate ? linkShareAuthenticationCodeEnabled ? 'EMAIL' : 'PUBLIC' : scopeType;
    const updatedScopeType = newSharedResources.length ? newPublicScope : 'PRIVATE';
    return await fetchJsonWithAuth<IShareResponse>(`${baseURL}/v1/sharing/share`, token, {
      body: JSON.stringify({
        accountKey,
        userKey,
        sessionKey,
        sharedResources: newSharedResources,
        expires_in: expirationEpoch,
        scope: [{
          type: updatedScopeType,
          value: scopeValue,
        }],
        isDownloadable,
      }),
      method: 'POST',
      mode: 'cors',
    });
  } catch (e) {
    throw {
      expirationEpoch,
      prevSharedResources,
    };
  }
};

export const updateShare = async (
  shareKey: string,
  token: IToken,
  shareObj: ISharePutObject
) => {
  try {
    return await fetchJsonWithAuth<IShareResponse>(`${baseURL}/v1/sharing/share`, token, {
      body: JSON.stringify({
        shareKey,
        ...shareObj,
      }),
      method: 'PUT',
      mode: 'cors',
    });
  } catch (e) {
    throw shareObj;
  }
};

export const updateSessionData = (
  updatedSessionData: ISessionUpdates, { userKey }: IUser, token: IToken, sessionKey
) => (
  fetchJsonWithAuth<any>(
    `${baseURL}/v2/users/${userKey}/sessions/${sessionKey}/meetings`,
    token, {
      method: 'PUT',
      body: JSON.stringify(updatedSessionData),
    }
  ).then(() => updateSessionData)
);

export const updateSpeakerName = (participantKey, name, { accountKey, userKey }: IUser, token: IToken, sessionKey) => (
  fetchJsonWithAuth<any>(
    `${baseURL}/v2/products/g2m/accounts/${accountKey}/users/${userKey}/sessions/${sessionKey}/participant`,
    token, {
      method: 'PATCH',
      body: JSON.stringify({
        participants: [{
          participantKey,
          name,
        }],
      }),
    }
  ).then(() => [{ participantKey, name, }])
);
